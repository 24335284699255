import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "./Logo"

const Header = ({ pageSlug }) => {
  const [menu, setMenu] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [isWorks, setIsWorks] = useState(false)
  const [isServices, setIsServices] = useState(false)
  const [isRestoration, setIsRestoration] = useState(false)
  const [isTeam, setIsTeam] = useState(false)
  const [isLocation, setIsLocation] = useState(false)

  useEffect(() => {
    // Prevent body scrolling in modal background
    if (menu === false) {
      document.body.classList.remove("lock-sp")
    } else {
      document.body.classList.add("lock-sp")
    }

    window.addEventListener("scroll", function () {
      if (pageSlug === "index-en") {
        // Handle scroll event
        const yOffset = window.pageYOffset
        if (yOffset > 700) {
          setScroll(true)
        } else {
          setScroll(false)
        }

        // Handle inview status
        const WorksClass = document.querySelector(".works").classList
        const ServicesClass = document.querySelector(".services").classList
        const RestorationClass = document.querySelector(".restoration")
          .classList
        const TeamClass = document.querySelector(".team").classList
        const LocationClass = document.querySelector(".location").classList
        if (WorksClass.contains("inview")) {
          setIsWorks("current")
        } else {
          setIsWorks("")
        }
        if (ServicesClass.contains("inview")) {
          setIsServices("current")
        } else {
          setIsServices("")
        }
        if (RestorationClass.contains("inview")) {
          setIsRestoration("current")
        } else {
          setIsRestoration("")
        }
        if (TeamClass.contains("inview")) {
          setIsTeam("current")
        } else {
          setIsTeam("")
        }
        if (LocationClass.contains("inview")) {
          setIsLocation("current")
        } else {
          setIsLocation("")
        }
      }
    })
  })

  function mobileMenuClose() {
    setMenu(false)
  }

  return (
    <>
      <header
        className={`header ${menu ? "is-open" : ""} ${
          scroll ? "scrolled" : ""
        }`}
      >
        <div className="header-bg"></div>
        <nav className="nav-global container-wide">
          <button
            className={`nav-button ${menu ? "is-active" : ""}`}
            type="button"
            aria-label="Menu"
            onClick={() => setMenu(!menu)}
          >
            <span className="ico-humbarger"></span>
          </button>

          <h1 className="nav-logo">
            <Link to="/en/">
              <Logo />
            </Link>
          </h1>

          <div className={`nav-menu ${menu ? "is-active" : "is-close"}`}>
            <ul className="nav-menu__lists">
              <li className={`nav-menu__list ${isWorks}`}>
                <Link
                  to="#works"
                  className={`nav-menu__link link-ul`}
                  onClick={mobileMenuClose}
                >
                  WORKS
                </Link>
              </li>
              <li className={`nav-menu__list ${isServices}`}>
                <Link
                  to="#services"
                  className="nav-menu__link link-ul"
                  onClick={mobileMenuClose}
                >
                  SERVICES
                </Link>
              </li>
              <li className={`nav-menu__list ${isRestoration}`}>
                <Link
                  to="#restoration"
                  className="nav-menu__link link-ul"
                  onClick={mobileMenuClose}
                >
                  RESTORATION
                </Link>
              </li>
              <li className={`nav-menu__list ${isTeam}`}>
                <Link
                  to="#team"
                  className="nav-menu__link link-ul"
                  onClick={mobileMenuClose}
                >
                  TEAM
                </Link>
              </li>
              <li className={`nav-menu__list ${isLocation}`}>
                <Link
                  to="#location"
                  className="nav-menu__link link-ul"
                  onClick={mobileMenuClose}
                >
                  LOCATION
                </Link>
              </li>
              <li className={`nav-menu__list nav-menu__list--lang`}>
                <a
                  href="https://cineric.jp/"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className="nav-menu__link"
                >
                  日本語
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
