import React from "react"
import Logo from "./Logo"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__branding">
        <div className="footer__branding__text">
          FOR ALL INQUIRIES EMAIL TO:
          <br />
          <span className="em">info@cinericcreative.com</span>
        </div>
        <div className="footer__branding__logo">
          <Logo />
        </div>
      </div>
      <div className="footer__copyright">
        &copy;{new Date().getFullYear()} Cineric Creative
      </div>
    </footer>
  )
}

export default Footer
