import React, { useEffect } from "react"
import PropTypes from "prop-types"

import SEO from "./SEO"
import "../assets/scss/styles.scss"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ lang, children, pageSlug, location }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  useEffect(() => {
    // Intersection Observer function
    const intersection = document.querySelectorAll(".intersection")
    const options = {
      root: null,
      rootMargin: "-50%",
      threshold: 0,
    }
    const observer = new IntersectionObserver(inView, options)
    intersection.forEach(intersection => {
      observer.observe(intersection)
    })

    function inView(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inview") // Add inview class to current section
        } else {
          entry.target.classList.remove("inview") // Remove inview class to current section
        }
      })
    }
  }, [pageSlug])

  return (
    <>
      <SEO lang={lang} pageSlug={pageSlug} location={location} />
      <Header lang={lang} pageSlug={pageSlug} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
